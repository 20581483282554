@use "@carbon/react/scss/breakpoint" as *;
@use "@carbon/react/scss/type" as *;
@use "@carbon/react/scss/spacing" as *;

@use "@carbon/react";
@use "@carbon/grid";
@use "@carbon/react/scss/colors";

// Emit the flex-grid styles
@include grid.flex-grid();

/// Remove overrides once Carbon bugs are fixed upstream.
/// Need grid option to not add page gutters at large viewports, to also use when nesting grids
/// @link https://github.com/carbon-design-system/carbon/issues/2792
@media (min-width: 42rem) {
  .cds--grid--no-gutter {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

/// Padding was introduced in 10.3.0, needs an option to let grid set the viewport gutter
/// @link https://github.com/carbon-design-system/carbon/issues/3010
.cds--content {
  margin-top: 3rem;
  padding: 0;
  background: var(--cds-background);
}

.cds--content .cds--css-grid {
  max-width: 100%;
  @include breakpoint(md) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

form .btn-block {
  max-width: 100%;
  width: 100%;
  display: block;
  text-align: center;
}

form .cds--inline-notification {
  max-width: 100%;
}

.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.screen__heading {
  @include type-style("heading-03");
  text-align: center;
}

.screen__label {
  margin-top: $spacing-04;
  flex-grow: 1; // fill space so icons are bottom aligned
  @include type-style("body-long-01");

  font-weight: bold;
  color: colors.$gray-30;
}

.screen__text--center {
  text-align: center;
}

.screen__centered_button_container {
  position: relative;
}

.screen__centered_button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.screen__notification_container {
  display: flex;
  width: 100%;
  justify-content: center;
}

.screen__tag_container {
  display: flex;
  justify-content: center;
}

.screen__center_loading {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
